import tableColumnsCache from "@/utils/tableColumnsCache"

const listVersion = 2
export const listName = 'fenaju.tradutores.lista'
export const listStorage = tableColumnsCache(listName, [
    {label: 'ID', name: 'id', active: false, sortable: true, ordering: 1},
    {label: 'Nome', name: 'nome', active: true, sortable: true, ordering: 3},
    {label: 'Idiomas', name: 'idiomas', active: true, sortable: true, ordering: 5},
    {label: 'UF', name: 'uf', active: true, sortable: true, ordering: 5},
    {label: 'Cidade', name: 'cidade', active: true, sortable: true, ordering: 5},
    {label: 'E-mail', name: 'email', active: true, sortable: true, ordering: 5},
    {label: 'Telefones', name: 'telefones', active: true, sortable: true, ordering: 5},
    //{label: 'Status', name: 'status', active: true, sortable: true, ordering: 12},
], listVersion)
