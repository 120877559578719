import http from '@/utils/services/http'

export const list = (limit, page, filtros, basic) => {
  let url = '/api/registro-tradutor?page=' + page + '&limit=' + limit + filtros
  return http.get(url)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const find = (id) => {
  let url = `/api/registro-tradutor/${id}`
  return http.get(url)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const newTradutor = (data) => {
  let url = `/api/registro-tradutor`
  return http.post(url, data)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const updateTradutor = (id, data, notifica = false) => {
  let url = `/api/registro-tradutor/${id}`
  if (notifica) {
    url = url + '?notifica=1'
  }
  return http.put(url, data)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}
export const deleteTradutor = (id) => {
  let url = `/api/registro-tradutor/${id}`
  return http.delete(url)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}
export const stats = () => {
  let url = `/api/registro-tradutor/stats`
  return http.get(url)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}
